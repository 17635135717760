define("ember-engines/-private/deprecate-transition-methods", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deprecateTransitionMethods = deprecateTransitionMethods;
  function deprecateTransitionMethods(frameworkClass, methodName) {
    (false && !(false) && (0, _debug.deprecate)(`Calling ${methodName} on a ${frameworkClass} is deprecated. Use the RouterService provided by \`ember-engines-router-service\` instead.`, false, {
      id: 'ember-engines.transition-methods',
      for: 'ember-engines',
      since: {
        available: '0.10.0',
        enabled: '0.10.0'
      },
      until: '1.0.0',
      url: 'https://ember-engines.com/docs/deprecations#-transition-methods-of-controller-and-route'
    }));
  }
});